.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dot-typing {
  position: relative;
  left: -9977px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00665e;
  color: #00665e;
  box-shadow: 9984px 0 0 0 #00665e, 9999px 0 0 0 #00665e, 10014px 0 0 0 #00665e;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #00665e, 9999px 0 0 0 #00665e,
      10014px 0 0 0 #00665e;
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 #00665e, 9999px 0 0 0 #00665e,
      10014px 0 0 0 #00665e;
  }

  33.333% {
    box-shadow: 9984px 0 0 0 #00665e, 9999px 0 0 0 #00665e,
      10014px 0 0 0 #00665e;
  }

  50% {
    box-shadow: 9984px 0 0 0 #00665e, 9999px -10px 0 0 #00665e,
      10014px 0 0 0 #00665e;
  }

  66.667% {
    box-shadow: 9984px 0 0 0 #00665e, 9999px 0 0 0 #00665e,
      10014px 0 0 0 #00665e;
  }

  83.333% {
    box-shadow: 9984px 0 0 0 #00665e, 9999px 0 0 0 #00665e,
      10014px -10px 0 0 #00665e;
  }

  100% {
    box-shadow: 9984px 0 0 0 #00665e, 9999px 0 0 0 #00665e,
      10014px 0 0 0 #00665e;
  }
}

.dropdown {
  position: absolute;
  z-index: 100;
  top: calc(100% + 10px);
  left: 0;
  right: auto;
  min-width: 120px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown button {
  display: block;
  width: 100%;
  padding: 8px 12px;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown button:hover {
  background-color: #f3f3f3;
}

.annotationLayer {
  height: calc(var(--scale-factor) * 200px) !important;
  display: none !important;
}

.react-pdf__Page__textContent {
  width: 100% !important;
  display: none !important;
  padding: 25px !important;
}

.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.pdf-container canvas {
  max-width: 100%;
  height: auto;
}

.rotateImage {
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (min-width: 1024px) {
  .rotateImage {
    background-image: url("./assets/rotate.png");
  }
}

.loadingSpinner {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000080;
  left: 0%;
  top: 0;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container {
  width: 1116px;
}

.icon-container .lets-icons.progress {
  font-size: 20px;
  animation: rotate 2s linear infinite;
  color: #00665e;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.table-container {
  background-color: #fff;
}

.table-container thead th {
  padding: 0.75rem 1.5rem;
  text-align: left;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #4b5563;
  background-color: #e5e7eb;
  border-bottom: 1px solid #d2d6dc;
  border: 1px solid #d2d6dc;
}

.table-container tbody td {
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 300;
  color: #4b5563;
  border: 1px solid #d2d6dc;
}

.table-container tbody tr {
  border-bottom: 1px solid #d2d6dc;
}

.table-container tbody tr:hover {
  background-color: #f3f4f6;
}

.table-container td.whitespace-nowrap {
  white-space: nowrap;
}

.rdw-emoji-modal {
  top: 21px !important;
  height: 115px !important;
}

.rdw-editor-toolbar {
  padding: 12px 16px !important;
  margin-bottom: 0px !important;
  border: 0px solid white !important;
  background-color: #f5f5f5 !important;
}

.rdw-editor-main {
  border: 0px solid white !important;
}

.rdw-option-wrapper {
  background-color: #f5f5f5 !important;
}

.rdw-option-wrapper:active {
  box-shadow: none !important;
}

.rdw-option-wrapper:hover {
  box-shadow: none !important;
}

.react-avatar__text {
  font-weight: bold !important;
}

.public-DraftStyleDefault-block {
  margin: 0px !important;
}

.rdw-editor-main {
  max-height: 300px !important;
  min-height: 150px !important;
  overflow-y: auto !important;
  padding: 10px !important;
}

.webkit-width {
  width: -webkit-fill-available;
}

.header-height {
  height: calc(100vh - 64px);
}

.pdf-preview {
  border-radius: 6px;
}

.pngFile {
  width: 250px !important;
  height: 250px !important;
}

.markdown-content {

  ul,
  ol {
    padding-left: 2em;
    margin-bottom: 1em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.75px;
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  h1 {
    font-size: 1.1em;
  }

  h2 {
    font-size: 1em;
  }

  h3 {
    font-size: 0.8em;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.75px;

  }

  p {
    margin-bottom: 1em;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.75px;
  }

  button {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.75px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
}

.header-heigth-1 {
  height: calc(100vh - 64px);
}


.dashboard-sidebar-container {
  order: 1;
}

.dashboard-content-container {
  order: 2;
}

.dashboard-chat-container {
  order: 3;
  width: 100%;
}

/* Dadshboard */
@media screen and (max-width: 767px) {
  .dashboard-icon-container {
    width: 100%;
  }

  .dashboard-sidebar {
    order: 3;
  }

  .dashboard-sidebar-container {
    order: 3;
  }

  .dashboard-content-container {
    order: 1;
  }

  .dashboard-chat-container {
    order: 2;
    width: 100%;
  }

  /* .my-pb-20 {
    padding-bottom: 5rem;
  } */

  .dashboard-sidebar {
    width: 100% !important;
    position: fixed;
    top: 88%;
    left: 0%;
    z-index: 12;
    height: 11% !important;

  }

  .message-editor {
    position: fixed;
    bottom: 12%;
    left: 0%;
    width: calc(100% - 20px);
    background: white;
    margin: 0px 10px;
  }

  .header-heigth-1 {
    height: 60vh;
  }
}

@media screen and (max-width: 479px) {
  .account-table {
    ::-webkit-scrollbar {
      display: none;
    }
  }

}

@media screen and (max-width: 426px) {

  .search-container input {
    font-size: 12px;
  }

  .dashboard-icon-container {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dashboard-content-container {
    width: 100% !important;

  }

  .sidebar {
    width: 100% !important;
  }
}

@media screen and (max-width: 375px) {
  .bg-lendingButton {
    font-size: 12px;
  }
}